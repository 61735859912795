import type Api from 'types/next-api';

export interface GroupedMobileOffersStatistic {
  month: string;
  chain: string;
  createdMobileOffers: number;
  storesInvolved: string[];
  targetedHouseholds: number;
  totalAveragePull: number;
}

interface IntermediateGroupedMobileOffersStatistic extends Omit<GroupedMobileOffersStatistic, 'totalAveragePull'> {
  pullSum: number;
  deliveryCount: number;
}

/**
 * Groups mobile offers statistics by month and chain.
 *
 * @param data - An array of mobile offers statistics response objects.
 * @returns An array of grouped mobile offers statistics.
 *
 * @example
 * ```typescript
 * const data = [
 *   {
 *     date: '2023-01-15',
 *     chain: 'ChainA',
 *     createdMobileOffers: 10,
 *     storesInvolved: ['Store1', 'Store2'],
 *     targetedHouseholds: 100,
 *     averagePull: 5,
 *   },
 *   {
 *     date: '2023-01-20',
 *     chain: 'ChainA',
 *     createdMobileOffers: 5,
 *     storesInvolved: ['Store3'],
 *     targetedHouseholds: 50,
 *     averagePull: 3,
 *   },
 *   {
 *     date: '2023-02-10',
 *     chain: 'ChainB',
 *     createdMobileOffers: 8,
 *     storesInvolved: ['Store4'],
 *     targetedHouseholds: 80,
 *     averagePull: 4,
 *   },
 * ];
 *
 * const result = groupMobileOffersStatistics(data);
 * console.log(result);
 * // Output:
 * // [
 * //   {
 * //     month: '2023-01',
 * //     chain: 'ChainA',
 * //     createdMobileOffers: 15,
 * //     storesInvolved: ['Store1', 'Store2', 'Store3'],
 * //     targetedHouseholds: 150,
 * //     totalAveragePull: 4,
 * //   },
 * //   {
 * //     month: '2023-02',
 * //     chain: 'ChainB',
 * //     createdMobileOffers: 8,
 * //     storesInvolved: ['Store4'],
 * //     targetedHouseholds: 80,
 * //     totalAveragePull: 4,
 * //   },
 * // ]
 * ```
 */
export const groupMobileOffersStatistics = (
  data: Api.Components.Responses.AggregatedMobileOffersStatistics,
): GroupedMobileOffersStatistic[] => {
  const groupedData: { [key: string]: IntermediateGroupedMobileOffersStatistic } = data.reduce((acc, item) => {
    const month = item.date.slice(0, 7);
    const key = `${month}-${item.chain}`;

    if (!acc[key]) {
      acc[key] = {
        month,
        chain: item.chain,
        createdMobileOffers: 0,
        storesInvolved: [],
        targetedHouseholds: 0,
        pullSum: 0,
        deliveryCount: 0,
      };
    }

    acc[key].createdMobileOffers += item.createdMobileOffers;
    acc[key].storesInvolved.push(...item.storesInvolved);
    acc[key].targetedHouseholds += item.targetedHouseholds;
    acc[key].pullSum += item.averagePull;
    acc[key].deliveryCount += 1;
    return acc;
  }, {});

  return Object.values(groupedData).map(({ pullSum, deliveryCount, ...item }) => ({
    ...item,
    storesInvolved: Array.from(new Set(item.storesInvolved)),
    totalAveragePull: pullSum / deliveryCount,
  }));
};
