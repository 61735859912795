import React, { useEffect, useState } from 'react';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import StatisticsStore from 'stores/statisticsStore';
import Spinner from 'components/common/next/spinner';
import * as dateFns from 'date-fns';
import _ from 'utils/lodash';
import { ChainAbbreviations, kRautaChainIds, kRuokaChainIds, onninenChainIds } from 'constants/common';
import ResultsContainer from '../resultsContainer';
import ChainSelector from 'components/next/components/chainSelector';
import { DateField } from 'components/next/components/form/input';
import { dateFormat } from 'components/next/utils';
import useMobileOffersStatistics from '../hooks/useMobileOffersStatistics';

interface Props {
  children?: React.ReactNode;
}

interface SearchFilters {
  deliveryDateFrom: Date | null;
  deliveryDateTo: Date | null;
  chainIds: string[];
}

const MobileOffers: React.FC<Props> = ({ children }) => {
  const { loading, statistics, fetchStatistics } = useMobileOffersStatistics();

  const [filters, setFilters] = useState<SearchFilters>({
    deliveryDateFrom: null,
    deliveryDateTo: null,
    chainIds: kRuokaChainIds,
  });

  useEffect(() => {
    fetchStatistics({
      chainIds: filters.chainIds,
      ...{
        startDate: filters.deliveryDateFrom ? dateFns.format(filters.deliveryDateFrom, 'yyyy-MM-dd') : undefined,
      },
      ...{
        endDate: filters.deliveryDateTo ? dateFns.format(filters.deliveryDateTo, 'yyyy-MM-dd') : undefined,
      },
    });
  }, [fetchStatistics, filters]);

  const renderMobileOffersTable = () => {
    const dateFormat = 'MMMM yyyy';

    return (
      <>
        <table className="styled">
          <thead>
            <tr>
              <th>Chain</th>
              <th>Month / Year</th>
              <th>Created mobile offers</th>
              <th>Stores that created mobile offers</th>
              <th>Total number of targeted Plussa households</th>
              <th>Total average pull</th>
            </tr>
          </thead>
          <tbody>
            {_.orderBy(statistics, 'month', 'desc').map((statistic, index) => (
              <tr key={`mobile-offer-${index}`}>
                <td>
                  <span className={`chain-name ${ChainAbbreviations[statistic.chain]}`}>
                    {ChainAbbreviations[statistic.chain]}
                  </span>
                </td>
                <td>{dateFns.format(dateFns.parse(statistic.month, 'yyyy-MM', new Date()), dateFormat)}</td>
                <td>{statistic.createdMobileOffers}</td>
                <td>{statistic.storesInvolved.length}</td>
                <td>{statistic.targetedHouseholds}</td>
                <td>{statistic.totalAveragePull.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && <Spinner />}
      </>
    );
  };

  return (
    <SidebarWrapper
      renderSidebar={() => (
        <div className="mobile-offers-sidebar">
          <div className="sidebar__title-wrapper">
            <h3 className="sidebar__title">Filters</h3>
            {loading && <Spinner addClassName="spinner--unset" />}
          </div>
          <div className="filter-group">
            <label>Chain</label>
            <ChainSelector
              chainSelection={filters.chainIds}
              handleChainChange={(pressedChain) => {
                if (filters.chainIds.includes(pressedChain)) {
                  setFilters({
                    ...filters,
                    chainIds: filters.chainIds.filter((chainId) => chainId !== pressedChain),
                  });
                } else {
                  setFilters({
                    ...filters,
                    chainIds: [...filters.chainIds, pressedChain],
                  });
                }
              }}
              excludeChains={[...kRautaChainIds, ...onninenChainIds]}
            />
          </div>
          <div className="filter-group">
            <label>Delivery time</label>
            <DateField
              value={filters.deliveryDateFrom}
              label="From"
              onChange={(deliveryDateFrom) => setFilters({ ...filters, deliveryDateFrom })}
              clearable
              placeholder={dateFormat}
            />
            <DateField
              value={filters.deliveryDateTo}
              label="To"
              onChange={(deliveryDateTo) => setFilters({ ...filters, deliveryDateTo })}
              clearable
              placeholder={dateFormat}
            />
          </div>
        </div>
      )}
    >
      <div className="results-view">
        {children}
        <ResultsContainer
          results={[
            {
              value: statistics.reduce((sum, statistic) => sum + statistic.createdMobileOffers, 0),
              detail: 'mobile offers created',
            },
            {
              value: new Set(statistics.flatMap((statistic) => statistic.storesInvolved)).size,
              detail: 'participating stores',
            },
            {
              value: statistics.reduce((sum, statistic) => sum + statistic.targetedHouseholds, 0),
              detail: 'recipients',
            },
          ]}
        />
        {renderMobileOffersTable()}
      </div>
    </SidebarWrapper>
  );
};

export default MobileOffers;
