/* eslint-disable @typescript-eslint/no-floating-promises, 	react/prop-types  */
import React from 'react';
import * as dateFns from 'date-fns';
import _ from 'utils/lodash';
import { inject, observer } from 'mobx-react';
import page from 'components/next/pages/page/page';
import StoreStore from 'stores/next/stores';
import PushNotificationsStore from 'stores/next-retailer/pushNotificationsStore';
import SettingsStore from 'stores/next/setting';
import AlertStore from 'stores/next/alerts';
import Spinner from 'components/common/next/spinner';
import SidebarWrapper from 'components/next/components/sidebar/sidebar';
import { DateField } from 'components/next/components/form/input';
import Alerts from 'components/common/next/alert/alerts';
import { castDate } from 'utils/helpers';
import StatisticsStore from 'stores/statisticsStore';
import type { Components } from 'types/next-api';
import './bestPerforming.scss';
import { dateFormat, payloadDateFormat } from 'components/next/utils';
import { ChainAbbreviations } from 'constants/common';

interface Props {
  pushNotificationsStore: PushNotificationsStore;
  storeStore: StoreStore;
  settingsStore: SettingsStore;
  alertStore: AlertStore;
  statisticsStore: StatisticsStore;
}

interface State {
  isLoading: boolean;
  startDateFrom: string;
  startDateTo: string;
  statistics: Components.Responses.BestPerformingTabStatisticsResponse;
}

@inject('storeStore', 'alertStore', 'statisticsStore')
@observer
class BestPerforming extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      startDateFrom: dateFns.format(
        dateFns.sub(new Date(), {
          months: 1,
        }),
        'yyyy-MM-dd',
      ),
      startDateTo: dateFns.format(new Date(), 'yyyy-MM-dd'),
      statistics: { offerResults: [], emailResults: [], mobileOffersResults: [] },
    };
  }

  get statistics() {
    return this.state.statistics;
  }

  get stores() {
    return this.props.storeStore.stores;
  }

  get alertStore() {
    return this.props.alertStore;
  }

  getStoreForNotification = (storeId: string) => {
    const result = _.find(this.stores, (store) => store.storeId === storeId);
    if (result) {
      return result.name;
    }
    return 'Unknown store';
  };

  componentDidMount = async () => {
    await this.props.statisticsStore.getStatisticsBestPerforming();
    await this.handleStatisticsMapping();
    await this.props.storeStore.searchStores({});
  };

  handleDateChange = (date: Date, field: string) => {
    const value = dateFns.isDate(date) ? dateFns.format(date, payloadDateFormat) : undefined;
    this.setState<never>({ [field]: value || null, isLoading: true });
    this.debouncedHandleStatisticsMapping();
  };

  // eslint-disable-next-line
  handleStatisticsMapping = async () => {
    this.setState({ isLoading: true });
    const { startDateFrom, startDateTo } = this.state;
    const { statisticsStore } = this.props;

    const offerStatistics = statisticsStore.offerStatisticsArray;
    const emailStatistics = statisticsStore.deliveryStatisticsArray;
    const mobileOffersStatistics = statisticsStore.mobileOffersStatisticsArray;

    const startDateFromDate = this.state.startDateFrom ? castDate(startDateFrom) : null;
    const startDateToDate = this.state.startDateTo ? castDate(startDateTo) : null;

    // Filter offer statistics by date
    const filteredOffers = offerStatistics.filter((statistic) => {
      const statisticDate = castDate(statistic.offerDate);
      if (startDateFromDate && startDateToDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate) && dateFns.isBefore(statisticDate, startDateToDate);
      } else if (startDateFromDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate);
      } else if (startDateToDate) {
        return dateFns.isBefore(statisticDate, startDateToDate);
      } else {
        return offerStatistics; // No date filter
      }
    });

    // Filter email statistics by date
    const filteredEmails = emailStatistics.filter((statistic) => {
      const statisticDate = castDate(statistic.emailDate);
      if (startDateFromDate && startDateToDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate) && dateFns.isBefore(statisticDate, startDateToDate);
      } else if (startDateFromDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate);
      } else if (startDateToDate) {
        return dateFns.isBefore(statisticDate, startDateToDate);
      } else {
        return emailStatistics; // No date filter
      }
    });

    // Filter mobile offer statistics by date
    const filteredMobileOffers = mobileOffersStatistics.filter((statistic) => {
      const statisticDate = castDate(statistic.date);
      if (startDateFromDate && startDateToDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate) && dateFns.isBefore(statisticDate, startDateToDate);
      } else if (startDateFromDate) {
        return dateFns.isAfter(statisticDate, startDateFromDate);
      } else if (startDateToDate) {
        return dateFns.isBefore(statisticDate, startDateToDate);
      } else {
        return true; // No date filter
      }
    });

    // Get the top 10 and top 20 offers. The source data should already be sorted.
    const offers = filteredOffers.slice(0, 10);
    const emails = filteredEmails.slice(0, 20);
    const mobileOffers = filteredMobileOffers.slice(0, 10);

    this.setState({
      statistics: { offerResults: offers, emailResults: emails, mobileOffersResults: mobileOffers },
      isLoading: false,
    });
  };

  debouncedHandleStatisticsMapping = _.debounce(this.handleStatisticsMapping, 500);

  renderBestOffersTable = () => (
    <>
      <h2 className="best-performing-title">Top 10 best offers</h2>
      <div className="table-container">
        <table className="styled">
          <thead>
            <tr>
              <th>ID</th>
              <th>Delivery template</th>
              <th>Offer</th>
              <th>Store</th>
              <th>Pull</th>
              <th>Redeemed offers</th>
            </tr>
          </thead>
          <tbody>
            {this.statistics.offerResults.map((statistic, index) => (
              <tr key={`offer-${index}`}>
                <td>{statistic.offerId}</td>
                <td>{statistic.deliveryTemplateName}</td>
                <td>{statistic.offerTitle}</td>
                <td>{`${statistic.storeId}, ${this.getStoreForNotification(statistic.storeId)}`}</td>
                <td>{statistic.pullPercentage.toFixed(2) + '%'}</td>
                <td>{statistic.redeemedOffers}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.isLoading && <Spinner />}
      </div>
    </>
  );

  renderBestEmailsTable = () => (
    <>
      <h2 className="best-performing-title">Top 20 best emails</h2>
      <div className="table-container">
        <table className="styled">
          <thead>
            <tr>
              <th>ID</th>
              <th>Delivery template</th>
              <th>Email title</th>
              <th>Chain</th>
              <th>Opened</th>
              <th>Clicked</th>
            </tr>
          </thead>
          <tbody>
            {this.statistics.emailResults.map((statistic, index) => (
              <tr key={`email-${index}`}>
                <td>{statistic.deliveryId}</td>
                <td>{statistic.deliveryTemplateName}</td>
                <td>{statistic.emailTitle}</td>
                <td>
                  {
                    <span
                      className={`chain-name ${ChainAbbreviations[statistic.chainId]}`}
                      key={`chain-${statistic.chainId}`}
                    >
                      {ChainAbbreviations[statistic.chainId]}
                    </span>
                  }
                </td>
                <td>{`${(statistic.openPercentage * 100).toFixed(2)}%`}</td>
                <td>{`${(statistic.clickPercentage * 100).toFixed(2)}%`}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.isLoading && <Spinner />}
      </div>
    </>
  );

  renderBestMobileOffersTable = () => (
    <>
      <h2 className="best-performing-title">Top 10 best mobile offers</h2>
      <div className="table-container">
        <table className="styled">
          <thead>
            <tr>
              <th>ID</th>
              <th>Offer name</th>
              <th>Offer</th>
              <th>Store</th>
              <th>Pull</th>
              <th>Redeemed offers</th>
            </tr>
          </thead>
          <tbody>
            {this.statistics.mobileOffersResults.map((statistic, index) => (
              <tr key={`offer-${index}`}>
                <td>{statistic.deliveryId}</td>
                <td>{statistic.offerName}</td>
                <td>{statistic.offerDetail}</td>
                <td>{`${statistic.storeId}, ${this.getStoreForNotification(statistic.storeId)}`}</td>
                <td>{statistic.pull.toFixed(2) + '%'}</td>
                <td>{statistic.redeemedOffers}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {this.state.isLoading && <Spinner />}
      </div>
    </>
  );

  renderSidebar = () => {
    const { isLoading } = this.state;
    return (
      <div className="best-performing-sidebar">
        <div className="sidebar__title-wrapper">
          <h3 className="sidebar__title">Filters</h3>
          {isLoading && <Spinner addClassName="spinner--unset" />}
        </div>
        <div className="filter-group">
          <label>Delivery time</label>
          <DateField
            value={this.state.startDateFrom ? new Date(this.state.startDateFrom) : null}
            label="From"
            onChange={(e) => this.handleDateChange(e, 'startDateFrom')}
            clearable
            placeholder={dateFormat}
          />
          <DateField
            value={this.state.startDateTo ? new Date(this.state.startDateTo) : null}
            label="To"
            onChange={(e) => this.handleDateChange(e, 'startDateTo')}
            clearable
            placeholder={dateFormat}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <SidebarWrapper renderSidebar={this.renderSidebar}>
        <div className="results-view">
          {this.props.children}
          <div className="best-performing">
            {this.renderBestOffersTable()}
            {this.renderBestEmailsTable()}
            {this.renderBestMobileOffersTable()}
          </div>
        </div>
        <Alerts />
      </SidebarWrapper>
    );
  }
}

export default page(BestPerforming);
