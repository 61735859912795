/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument, 	@typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, 	@typescript-eslint/no-unsafe-member-access */
import * as _ from 'lodash';
import { NotificationContactType } from 'types/user';
import queryString from 'query-string';
import * as date from 'date-fns';
import { addDays, isSaturday, isSunday, isWeekend, parseISO, subDays } from 'date-fns';
import { DT_DEFAULT_DEADLINE_AFTER, DT_DEFAULT_DELIVERY_AFTER, DT_DEFAULT_LAST_EDIT_AFTER } from 'utils/constants';
import { kRautaChainIds, ChainAbbreviations, kRuokaChainIds, onninenChainIds } from 'constants/common';
import { Chain, ConceptType } from 'enums/common';
import { useEffect } from 'react';
import { DeliveryTemplate } from 'stores/next-retailer/templateStore';
import { Delivery } from 'stores/next-retailer/deliveryStore';
import deliveryTemplate from 'components/next/pages/deliveryTemplate';
import { ContentField, DeliveryField } from 'types/next';
import { toJS } from 'mobx';

export const addBackUrl = (location) => {
  const previous = (location.state && location.state.backUrls) || [];
  return { ...location.state, backUrls: [...previous, location.pathname] };
};

export const getBackLocation = (location, fallback: string) => {
  const urls: string[] = _.get(location, 'state.backUrls');
  const newUrls = urls ? _.slice(urls, 0, urls.length - 1) : [];
  return {
    pathname: _.last(urls) || fallback,
    state: { ...location.state, backUrls: newUrls },
  };
};

export const scrollToTop = () => {
  const mainContents = document.querySelectorAll('.main-content');
  const mainContent = _.last(mainContents);
  if (mainContent && typeof mainContent.scrollTo === 'function') {
    mainContent.scrollTo(0, 0);
  }
};

export const noSubmit = (event) => (event.keyCode === 13 ? event.preventDefault() : null);

export const isOnninenChain = (chain: string | number) => onninenChainIds.includes(`${chain}`);
export const isKRautaChain = (chain: string | number) => kRautaChainIds.includes(`${chain}`);
export const isKRuokaChain = (chain: string | number) => kRuokaChainIds.includes(`${chain}`);
export const isNesteKChain = (chain: string) => chain === '9';

export interface ImgixOpts {
  fm?: string;
  w?: number;
  h?: number;
  fit?: string;
}

export interface CroppingOption {
  value: string;
  label: string;
  queryParams: string;
}

export const imageSrc = (url: string, opts: ImgixOpts) => {
  if (url.match('imgix') || url.match('public.keskofiles')) {
    return `${url}?${queryString.stringify(opts as any)}`;
  }
  return url;
};

export const croppingOptions: CroppingOption[] = [
  {
    value: 'square_crop',
    label: 'Square crop',
    queryParams: 'fm=png&w=480&h=480&fit=crop',
  },
  {
    value: 'square_fit_image',
    label: 'Square, fit image',
    queryParams: 'fit=fill&bg=fff&w=450&h=450',
  },
];

// Finnish translations
export const croppingTranslations = {
  en: {
    square_crop: 'Square crop',
    square_fit_image: 'Square, fit image',
  },
  fi: {
    square_crop: 'Neliörajaus',
    square_fit_image: 'Neliö, sovita kuva',
  },
};

export const applyCrop = (imageUrl: string, crop: string) => {
  const url = new URL(imageUrl);

  // Append or replace existing query parameters with the new crop parameters
  const params = new URLSearchParams(crop);
  for (const [key, value] of params) {
    url.searchParams.set(key, value);
  }

  return url.toString();
};

export const ensureString = (value: string | number | boolean): string => {
  return value != null ? value.toString() : '';
};

// Type guard to check if an array is ContentField[]
function isContentFieldArray(fields: any[]): fields is ContentField[] {
  return fields.length > 0 && fields.every((item) => 'type' in item);
}

// Type guard to check if an array is DeliveryField[]
function isDeliveryFieldArray(fields: any[]): fields is DeliveryField[] {
  return fields.length > 0 && fields.every((item) => 'contentField' in item);
}

export const replaceDotInPrice = (field: ContentField[] | DeliveryField[]): (ContentField | DeliveryField)[] => {
  const formatPrice = (price: number | string | null) => {
    if (typeof price === 'number') {
      return price.toFixed(2).replace('.', ',');
    }
    if (typeof price === 'string' && price.includes(',')) {
      return price; // Already formatted
    }
    return null;
  };
  const updatePrices = (obj) => ({
    normalPriceVat24: formatPrice(obj.normalPriceVat24),
    normalPriceVat0: formatPrice(obj.normalPriceVat0),
    salePriceVat24: formatPrice(obj.salePriceVat24),
    loyaltyPrice: formatPrice(obj.loyaltyPrice),
    omnibus: formatPrice(obj.omnibus),
});
  if (isContentFieldArray(field)) {
    return field.map((item) => {
      if (item.defaultValue) {
        const updatedItem = {
          ...item,
          defaultValue: {
            ...item.defaultValue,
            fi: (item.defaultValue.fi && typeof item.defaultValue.fi === 'object' && 'ean' in item.defaultValue.fi) ? {
              ...item.defaultValue.fi,
              ...updatePrices(item.defaultValue.fi),
            } : item.defaultValue.fi,
            sv: (item.defaultValue.sv && typeof item.defaultValue.sv === 'object' && 'ean' in item.defaultValue.sv) ? {
              ...item.defaultValue.sv,
              ...updatePrices(item.defaultValue.sv),
            } : item.defaultValue.sv,
          },
        };
        return updatedItem;
      }
      return item;
    });
  } else if (isDeliveryFieldArray(field)) {
    return field.map((item) => {
      if (item.value) {
        const updatedItem = {
          ...item,
          value: {
            ...item.value,
            fi: (item.value.fi && typeof item.value.fi === 'object' && 'ean' in item.value.fi) ? {
              ...item.value.fi,
              ...updatePrices(item.value.fi),
            } : item.value.fi,
            sv: (item.value.sv && typeof item.value.sv === 'object' && 'ean' in item.value.sv) ? {
              ...item.value.sv,
              ...updatePrices(item.value.sv),
            } : item.value.sv,
          },
        };
        return updatedItem;
      }
      return item;
    });
  }
  return field;
};

export const isEmail = (value: string): boolean => {
  const emailRE =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
  return emailRE.test(value.toLowerCase());
};

export const validateNotificationContact = (value: string, allowMobile = true) => {
  const numbers = value.match(/\d/g);
  // eslint-disable-next-line
  const others = value.match(/[^(\d\+\-\s)]/g);
  const isMobile =
    allowMobile &&
    numbers &&
    !others &&
    (numbers.length === 10 || (_.startsWith(value, '+358') && numbers.length === 12));

  return {
    type: isMobile ? NotificationContactType.mobile : NotificationContactType.email,
    valid: isMobile || isEmail(value) ? true : false,
  };
};

export const castDate = (datelike: number | string | Date) => {
  if (typeof datelike === 'string') {
    return parseISO(datelike);
  }
  return new Date(datelike);
};

export const addWeekDays = (from: Date, days: number) => {
  let initialOffset = 1;
  if (isSaturday(from)) {
    initialOffset = 2;
  } else if (isSunday(from)) {
    initialOffset = 3;
  }

  from = subDays(from, initialOffset);
  for (let day = 0; day <= days; day++) {
    from = addDays(from, 1);
    if (isSaturday(from)) {
      from = addDays(from, 2);
    } else if (isSunday(from)) {
      from = addDays(from, 1);
    }
  }

  return from;
};

export const subWeekdays = (from: Date, days: number) => {
  let weekdays = 0;
  while (weekdays < days) {
    from = subDays(from, 1);
    if (!isWeekend(from)) {
      weekdays++;
    }
  }
  return from;
};

export const getImageVariantUrl = (url: string, variant: 'crop' | 'thumb') =>
  url.replace(/(\.[\w\d_-]+)$/i, `_${variant}$1`);

export const getExcludedChains = (chainIds: string[]): string[] =>
  _.difference(Object.keys(ChainAbbreviations), chainIds);

export const getAllowedChains = (chain: Chain) => {
  switch (chain) {
    case Chain.kRuoka:
      return kRuokaChainIds;
    case Chain.kRauta:
      return kRautaChainIds;
    case Chain.onninen:
      return onninenChainIds;
    default:
      throw new Error(`Unexpected chain: ${chain}`);
  }
};

const hasChain = (chainIds: string[]) => ({
  hasKRautaChain: kRautaChainIds.some((r) => chainIds.indexOf(r) !== -1),
  hasKRuokaChain: kRuokaChainIds.some((r) => chainIds.indexOf(r) !== -1),
  hasOnninenChain: onninenChainIds.some((r) => chainIds.indexOf(r) !== -1),
});

const multipleChainError = (chainIds: string[]) =>
  'Chain ids contain multiple chains, which should never happen: ' + chainIds.join(', ');

// returns true, if given chain list contains only K-Rauta chain. Throws error, if chain list contains more than one chain
export const isKRautaOnlyChain = (chainIds: string[]) => {
  const { hasKRautaChain, hasKRuokaChain, hasOnninenChain } = hasChain(chainIds);
  if ([hasKRautaChain, hasKRuokaChain, hasOnninenChain].filter(Boolean).length > 1) {
    throw new Error(multipleChainError(chainIds));
  } else {
    return hasKRautaChain;
  }
};

// returns true, if given chain list contains only K-Ruoka chain. Throws error, if chain list contains more than one chain
export const isKRuokaOnlyChain = (chainIds: string[]) => {
  const { hasKRautaChain, hasKRuokaChain, hasOnninenChain } = hasChain(chainIds);
  if ([hasKRautaChain, hasKRuokaChain, hasOnninenChain].filter(Boolean).length > 1) {
    throw new Error(multipleChainError(chainIds));
  } else {
    return hasKRuokaChain;
  }
};

// returns true, if given chain list contains only Onninen chain. Throws error, if chain list contains more than one chain
export const isOnninenOnlyChain = (chainIds: string[]) => {
  const { hasKRautaChain, hasKRuokaChain, hasOnninenChain } = hasChain(chainIds);
  if ([hasKRautaChain, hasKRuokaChain, hasOnninenChain].filter(Boolean).length > 1) {
    throw new Error(multipleChainError(chainIds));
  } else {
    return hasOnninenChain;
  }
};

export const getChain = (chainIds: string[]) => {
  const { hasKRautaChain, hasKRuokaChain, hasOnninenChain } = hasChain(chainIds);
  if ([hasKRautaChain, hasKRuokaChain, hasOnninenChain].filter(Boolean).length > 1) {
    throw new Error(multipleChainError(chainIds));
  }
  if (hasKRautaChain) {
    return Chain.kRauta;
  }
  if (hasKRuokaChain) {
    return Chain.kRuoka;
  }
  if (hasOnninenChain) {
    return Chain.onninen;
  }
  throw new Error(`Unexpected chain id: ${chainIds.join(',')}`);
};

export const getAllowedConceptTypes = (chain: Chain): ConceptType[] => {
  switch (chain) {
    case Chain.kRuoka:
      return [ConceptType.Season, ConceptType.Program];
    case Chain.kRauta:
      return [ConceptType.Season, ConceptType.Program, ConceptType.B2b];
    case Chain.onninen:
      return [ConceptType.B2b];
    default:
      return [];
  }
};

export const getAllowedConceptTypeForChainIds = (chainIds: string[]): ConceptType[] => {
  if (!chainIds?.length) {
    return [];
  }
  const { hasKRautaChain, hasKRuokaChain, hasOnninenChain } = hasChain(chainIds);
  if ([hasKRautaChain, hasKRuokaChain, hasOnninenChain].filter(Boolean).length > 1) {
    throw new Error(multipleChainError(chainIds));
  }
  if (hasKRautaChain) {
    return getAllowedConceptTypes(Chain.kRauta);
  }
  if (hasKRuokaChain) {
    return getAllowedConceptTypes(Chain.kRuoka);
  }
  if (hasOnninenChain) {
    return getAllowedConceptTypes(Chain.onninen);
  }
};

export const getByType = (type: ConceptType, programResult: any, seasonResult: any, b2bResult: any) => {
  switch (type) {
    case ConceptType.Program:
      return programResult;
    case ConceptType.Season:
      return seasonResult;
    case ConceptType.B2b:
      return b2bResult;
    default:
      throw Error(`Unexpected type: ${type}`);
  }
};

const payloadDateFormat = 'yyyy-MM-dd';
const DEFAULT_DATE = date.format(castDate(new Date()), payloadDateFormat);

// Helper method for getting new dates for new and copied delivery template
export const getNewDeliveryTemplateDates = (
  conceptType: ConceptType,
): {
  publishDate: string;
  deadline: string;
  firstStartDate: string;
  lastStartDate: string;
  firstEditDate: string;
  lastEditDate: string;
  targetGroupLockDate: string;
} => {
  const publishDate = DEFAULT_DATE;
  const deadline = date.format(date.addDays(new Date(publishDate), DT_DEFAULT_DEADLINE_AFTER), payloadDateFormat);
  const firstStartDate = date.format(date.addDays(new Date(publishDate), DT_DEFAULT_DELIVERY_AFTER), payloadDateFormat);
  const lastStartDate = date.format(date.addDays(new Date(publishDate), DT_DEFAULT_DELIVERY_AFTER), payloadDateFormat);
  const firstEditDate = DEFAULT_DATE;
  const lastEditDate = date.format(date.addDays(new Date(publishDate), DT_DEFAULT_LAST_EDIT_AFTER), payloadDateFormat);
  const targetGroupLockDate =
    conceptType === ConceptType.Season || conceptType === ConceptType.B2b
      ? null
      : date.format(date.addDays(new Date(publishDate), DT_DEFAULT_DELIVERY_AFTER), payloadDateFormat);

  return {
    publishDate,
    deadline,
    firstStartDate,
    lastStartDate,
    firstEditDate,
    lastEditDate,
    targetGroupLockDate,
  };
};

// Divide operation, return zero if trying to divide with zero denominator
export const calculateDivisionSafe = (nominator: number, denominator: number) => {
  if (denominator === 0) {
    return 0;
  } else {
    const result: number = nominator / denominator;
    return result;
  }
};

// Validating that EAN is 13 digit long and calculating also checksum to validate it.
export const validateEan13 = (ean: string) => {
  if (!ean.match(/^[0-9]{13}$/)) {
    return false;
  }
  // Get the check number
  const originalCheck = Number(ean.substring(ean.length - 1));
  const tmpEanCode = ean.substring(0, ean.length - 1);

  // Add even numbers together and multiply this result by 3
  const even =
    (Number(tmpEanCode.charAt(1)) +
      Number(tmpEanCode.charAt(3)) +
      Number(tmpEanCode.charAt(5)) +
      Number(tmpEanCode.charAt(7)) +
      Number(tmpEanCode.charAt(9)) +
      Number(tmpEanCode.charAt(11))) *
    3;
  // Add odd numbers together
  const odd =
    Number(tmpEanCode.charAt(0)) +
    Number(tmpEanCode.charAt(2)) +
    Number(tmpEanCode.charAt(4)) +
    Number(tmpEanCode.charAt(6)) +
    Number(tmpEanCode.charAt(8)) +
    Number(tmpEanCode.charAt(10));
  // Add two totals together
  const total = even + odd;

  // Calculate the checksum
  // Divide total by 10 and store the remainder
  let checksum = total % 10;
  // If result is not 0 then take away 10
  if (checksum !== 0) {
    checksum = 10 - checksum;
  }
  // Return the result
  if (checksum !== originalCheck) {
    return false;
  }
  return true;
};

export const isValidEmail = (email: string) => {
  const pattern = new RegExp(
    // eslint-disable-next-line
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  );
  return pattern.test(email);
};

export const getImageFileDimensions = async (file: File, minWidth: number, minHeight: number) => {
  const getImageDimensions = new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      window.URL.revokeObjectURL(img.src);
      return resolve({ width, height });
    };

    img.src = window.URL.createObjectURL(file);
  });

  const allowed = await getImageDimensions.then(({ width, height }) => {
    if (width < minWidth || height < minHeight) {
      return false;
    }
    return true;
  });

  return allowed;
};

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};
