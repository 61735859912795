import React from 'react';

interface Result {
  value: number;
  detail: string;
}

interface ResultsProps {
  results: Result[];
}

const ResultsContainer: React.FC<ResultsProps> = ({ results }) => {
  return (
    <div className="results-container">
      {results.map((result, index) => (
        <div className="result" key={index}>
          <span className="result__value">{result.value}</span>
          <span className="result__detail">{result.detail}</span>
        </div>
      ))}
    </div>
  );
};

export default ResultsContainer;
