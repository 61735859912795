import { useState, useCallback } from 'react';
import type Api from 'types/next-api';
import type { Client } from 'types/next-api';
import * as api from 'utils/next-api';
import { GroupedMobileOffersStatistic, groupMobileOffersStatistics } from '../domain/mobile-offers-statistics';

interface Response {
  loading: boolean;
  statistics: GroupedMobileOffersStatistic[];
  fetchStatistics: (request?: Api.Components.Schemas.AggregatedMobileOffersStatisticsSearch) => void;
}

const useMobileOffersStatistics = (): Response => {
  const [statistics, setStatistics] = useState<GroupedMobileOffersStatistic[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchStatistics = useCallback((request) => {
    const { startDate, endDate, chainIds } = request || {};
    setLoading(true);
    api.getClient<Client>().then((client) => {
      client
        .searchAggregatedMobileOffersStatistics(null, {
          startDate,
          endDate,
          chainIds,
        })
        .then((response) => setStatistics(groupMobileOffersStatistics(response.data)))
        .finally(() => setLoading(false));
    });
  }, []);

  return {
    loading,
    statistics,
    fetchStatistics,
  };
};

export default useMobileOffersStatistics;
